import { AUTHORIZATION_HEADER } from '../../../../src/authorization';
import { send } from '../../../../src/network';
import { type HrError } from '../../../../src/types/HrError';
import { type Lead } from '../../../../src/types/Lead';

export async function postLeads(params: Lead) {
  return await send<HrError>({
    path: '/api/v2/leads/',
    method: 'POST',
    body: params,
    headers: new Headers({
      'Content-Type': 'application/json',
      AUTHORIZATION: AUTHORIZATION_HEADER,
    }),
  });
}
