export interface Lead {
  email?: string;
  phone_number?: string;
  source?: LeadSource | null;
}

export enum LeadSource {
  EXIT_INTENT_MODAL = 'exit_intent_modal',
  ADMIN = 'admin',
  VARIANT_0 = 'variant-0',
  VARIANT_1 = 'variant-1',
  VARIANT_2 = 'variant-2',
  VARIANT_3 = 'variant-3',
  LANDING_0 = 'landing-0',
  PROMO_1 = 'promo-1',
  FACEBOOK = 'facebook',
  FACEBOOK_1 = 'facebook-1',
}
