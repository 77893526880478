import { useMutation } from '@tanstack/react-query';
import { type Lead, StatusCode } from '@hungryroot/rest';
import { postLeads } from '@hungryroot/rest/api/v2/leads/post/post-api-v2-leads';

export function useCreateLead() {
  return useMutation({
    mutationFn: async (body: Lead) => {
      const resp = await postLeads(body);
      if (resp.status !== StatusCode.SuccessAccepted) {
        throw new Error(JSON.stringify(resp.data, null, 2));
      }
      return resp.data;
    },
  });
}
