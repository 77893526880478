import { useEffect, type ReactNode } from 'react';
import {
  getFormProps,
  getInputProps,
  useForm,
  useInputControl,
} from '@conform-to/react';
import { parseWithZod } from '@conform-to/zod';
import { z } from 'zod';
import { log } from '@hungryroot/o11y';
import { TextInput } from '@hungryroot/ramen';
import { type Lead, LeadSource } from '@hungryroot/rest';
import styles from './EmailInput.module.css';

const EmailSchema = z
  .string({
    required_error: 'Enter a valid email',
  })
  .email({ message: 'Enter a valid email' });

const EmailLeadSchema = z.object({
  email: EmailSchema,
});

type EmailInputProps = {
  onSubmit: (data: Lead) => Promise<unknown>;
  children: ReactNode;
};

export const EmailInput = (props: EmailInputProps) => {
  const { onSubmit, children } = props;

  useEffect(() => {
    analytics.track('Exit Modal with Email');
    log('modal-exit-intent', {
      scope: 'email',
      event: 'lead-shown',
    });
  }, []);

  const [form, fields] = useForm({
    id: 'email-input-lead-form',
    defaultValue: {
      email: '',
    },
    shouldValidate: 'onBlur',
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: EmailLeadSchema });
    },
    onSubmit: async (e, { formData }) => {
      log('modal-exit-intent', {
        scope: 'email',
        event: 'lead-submission-attempt',
      });
      e.preventDefault();

      const submission = parseWithZod(formData, {
        schema: EmailLeadSchema,
      });

      if (submission.status !== 'success') {
        return;
      }
      const response = await onSubmit({
        email: submission.value.email,
        source: LeadSource.EXIT_INTENT_MODAL,
      });

      log('modal-exit-intent', {
        scope: 'email',
        event: 'lead-submission-success',
      });

      return response;
    },
  });

  const email = useInputControl(fields.email);

  return (
    <form className={styles.item} {...getFormProps(form)}>
      <TextInput
        className={styles.input}
        inputProps={getInputProps(fields.email, {
          type: 'email',
        })}
        type="email"
        label="Email"
        value={email.value || ''}
        errorMessage={fields.email.errors}
        onChange={email.change}
      />
      {children}
    </form>
  );
};
