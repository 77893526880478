import { type ReactNode, useState } from 'react';
import { useCookie, useLocation } from 'react-use';
import { useExperiment } from '@hungryroot/experiments';
import { LeadExitIntentModal } from '../LeadExitIntentModal';
import {
  ModalExitIntentModal,
  type Props as ModalExitIntentModalProps,
} from '../Modal/Modal';
import {
  VisibilityController,
  type Props as VisibilityControllerProps,
} from '../VisibilityController/VisibilityController';

export const COOKIE_STORAGE_NAME = 'exit-modal';
export const COOKIE_ALREADY_SEEN_VALUE = '1';
export const DEFAULT_IDLE_DELAY_MILLISECONDS = 15000;
const NEW_COOKIE_STORAGE_NAME = 'new-exit-intent-modal';

type ModalExitINtentContainerProps = {
  backgroundImage: ModalExitIntentModalProps['backgroundImage'];
  variantImage?: ReactNode;
} & Pick<Partial<VisibilityControllerProps>, 'idleDelayMilliseconds'>;

type ModalExitIntentProps = {
  backgroundImage: ModalExitIntentModalProps['backgroundImage'];
  variantImage?: ReactNode;
  variant: number;
} & Pick<Partial<VisibilityControllerProps>, 'idleDelayMilliseconds'>;

export const ModalExitIntentContainer = ({
  backgroundImage,
  variantImage,
  idleDelayMilliseconds = DEFAULT_IDLE_DELAY_MILLISECONDS,
}: ModalExitINtentContainerProps) => {
  const variant = useExperiment(150);

  if (variant === undefined) {
    return null;
  }

  return (
    <ModalExitIntent
      backgroundImage={backgroundImage}
      variantImage={variantImage}
      idleDelayMilliseconds={idleDelayMilliseconds}
      variant={variant}
    />
  );
};

export function ModalExitIntent({
  backgroundImage,
  variantImage,
  idleDelayMilliseconds = DEFAULT_IDLE_DELAY_MILLISECONDS,
  variant,
}: ModalExitIntentProps) {
  const location = useLocation();
  const [cookieValue, setCookieValue] = useCookie(
    variant && variant > 0 ? NEW_COOKIE_STORAGE_NAME : COOKIE_STORAGE_NAME
  );
  // only care about the initial value for showing the modal
  const [initialCookieValue] = useState(cookieValue);
  const [couponRequestedCookie] = useCookie('coupon_requested');
  const [dismissed, setDismissed] = useState(() => {
    return (
      location.search?.includes('promo') ||
      couponRequestedCookie !== null ||
      initialCookieValue === COOKIE_ALREADY_SEEN_VALUE
    );
  });

  if (dismissed === true) {
    return null;
  }

  return (
    <VisibilityController
      onShow={() => {
        setCookieValue(COOKIE_ALREADY_SEEN_VALUE);
      }}
      idleDelayMilliseconds={idleDelayMilliseconds}
    >
      {variant > 0 ? (
        <LeadExitIntentModal
          testid="lead-exit-intent-modal"
          onClose={() => {
            setDismissed(true);
          }}
          backgroundImage={variantImage}
        ></LeadExitIntentModal>
      ) : (
        <ModalExitIntentModal
          onClose={() => {
            setDismissed(true);
          }}
          backgroundImage={backgroundImage}
        />
      )}
    </VisibilityController>
  );
}
