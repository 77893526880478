import { type ReactNode, useState } from 'react';
import { fullPageRedirect } from '@hungryroot/environment';
import { useExperiment } from '@hungryroot/experiments';
import { PhoneInput, EmailInput, useCreateLead } from '@hungryroot/leads';
import { ROUTE_QUIZ_INTRO } from '@hungryroot/quiz';

import {
  ModalContent,
  ModalOverlay,
  Heading,
  HeadingVariant,
  Button,
  Paragraph,
  ButtonSizeVariant as SizeVariant,
  ButtonStyleVariant as StyleVariant,
  ButtonWidthVariant as WidthVariant,
  ButtonProminenceVariant as ProminenceVariant,
} from '@hungryroot/ramen';

import { type Lead } from '@hungryroot/rest';
import styles from './LeadExitIntentModal.module.css';

interface LeadExitIntentModalProps {
  testid?: string;
  onClose: () => void;
  backgroundImage: ReactNode;
  children?: ReactNode;
  isLoading?: boolean;
}

export function LeadExitIntentModal(props: LeadExitIntentModalProps) {
  const { testid, onClose, backgroundImage, isLoading } = props;

  const backgroundImageElement = (
    <div className={styles['image-container']}>{backgroundImage}</div>
  );

  const [isOfferUnlocked, setIsOfferUnlocked] = useState(false);
  const [isErrored, setIsErrored] = useState(false);

  const createLead = useCreateLead();

  const variant = useExperiment(150);

  if (variant === undefined || variant < 1) {
    return null;
  }

  const submitButton = (
    <Button
      buttonType={isOfferUnlocked ? 'button' : 'submit'}
      onClick={
        isOfferUnlocked
          ? () => {
              fullPageRedirect(ROUTE_QUIZ_INTRO);
            }
          : undefined
      }
      loading={isLoading}
      className={styles.unlock}
      size={SizeVariant.Medium}
      style={StyleVariant.Inverted}
      prominence={ProminenceVariant.Primary}
      width={WidthVariant.FullWidth}
      textPrimary={isOfferUnlocked ? 'Get started' : 'Unlock offer'}
    />
  );

  const onSubmit = async (body: Lead) => {
    if (!isOfferUnlocked) {
      try {
        setIsErrored(false);
        await createLead.mutateAsync(body);
        setIsOfferUnlocked(true);
        analytics.track(
          `Exit Modal Confirmation with ${
            variant === 1 ? 'Phone Number' : 'Email Address'
          }`
        );
      } catch {
        setIsErrored(true);
      }
    }
  };

  return (
    <ModalOverlay
      className={styles.container}
      onDismiss={onClose}
      isOpen={true}
    >
      <ModalContent ariaLabel="discount" className={styles['modal-content']}>
        <div data-testid={testid} className={styles['content']}>
          {backgroundImageElement}
          <div className={styles['text-content']}>
            <Heading className={styles.discount} variant={HeadingVariant.H1Alt}>
              {isOfferUnlocked
                ? 'Woohoo! Your discount will apply at checkout.'
                : 'Get 30% off your first order + a free gift for life'}
            </Heading>

            {!isOfferUnlocked ? (
              variant === 1 ? (
                <PhoneInput onSubmit={onSubmit}>{submitButton}</PhoneInput>
              ) : (
                <EmailInput onSubmit={onSubmit}>{submitButton}</EmailInput>
              )
            ) : (
              submitButton
            )}
            {isErrored && (
              <Paragraph className={styles.error} testid="error-message">
                Something went wrong. Please try again.
              </Paragraph>
            )}
            <Button
              onClick={() => {
                onClose();
              }}
              size={SizeVariant.Medium}
              style={StyleVariant.Inverted}
              prominence={ProminenceVariant.Primary}
              width={WidthVariant.FullWidth}
              textPrimary="Close"
            />
          </div>
        </div>
      </ModalContent>
    </ModalOverlay>
  );
}
